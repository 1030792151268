import APIController from "../APIController";

class AnalysesController extends APIController {
  constructor() {
    super("/analyse");
  }

  async getOrganismesStockeurs() {
    return await this.getRequest("/organismesstockeurs");
  }

  async getCotisationsImpayees(millesime) {
    return await this.getRequest("/cotisationsimpayees", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getComparatifContratConvention(millesime) {
    return await this.getRequest("/comparatifcontratconvention", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getListeRouge() {
    return await this.getRequest("/listerouge");
  }

  async getComparatifContratIntention(millesime) {
    return await this.getRequest("/comparatifcontratintention", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getContratsNonRentres(millesime) {
    return await this.getRequest("/contratsnonrentres", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getContratsIncomplets(millesime) {
    return await this.getRequest("/contratsincomplets", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getLivraisonsNonSaisies(millesime) {
    return await this.getRequest("/livraisonsnonsaisies", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getExploitationsZoneProduction() {
    return await this.getRequest("/exploitationszoneproduction");
  }

  async getSurfaceVarieteOS(millesime) {
    return await this.getRequest("/surfacevarieteos", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getIntentionsSemisNonRentrees(millesime) {
    return await this.getRequest("/intentionsemisnonrentree", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getExploitationsSansProducteurs() {
    return await this.getRequest("/exploitationsansproducteur");
  }

  async getConformitesJacheresMelliferes(millesime) {
    return await this.getRequest(
      `/conformitejacheremellifere?millesime=${millesime}`,
    );
  }

  async getTracabiliteContrats(millesime) {
    return await this.getRequest("/tracabilitecontrats", {
      params: {
        millesime: millesime,
      },
    });
  }

  async getCodesOs() {
    return await this.getRequest("/codeos");
  }

  async updateCodeOs(idexploitation, idorganismestockeur, code) {
    return await this.putRequest(
      `/codeos?idexploitation=${idexploitation}&idorganismestockeur=${idorganismestockeur}&code=${code}`,
    );
  }

  async updateCodeDc(idexploitation, code) {
    return await this.putRequest(
      `/codeos/codedc?idexploitation=${idexploitation}&code=${code}`,
    );
  }

  async updateDateReceptionTracabilite(idcontrat, datereceptiontracabilite) {
    return await this.putRequest(
      `/tracabilitecontrats?idcontrat=${idcontrat}${
        datereceptiontracabilite != null
          ? `&datereceptiontracabilite=${datereceptiontracabilite}`
          : ""
      }`,
    );
  }
}

export default new AnalysesController();
