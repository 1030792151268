import Analyses from "../Analyses";
import { useContext, useState } from "react";
import StoreContext from "../../context/StoreContext";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import {
  convertToCSV,
  createNotification,
  downloadCsv,
  RenderIf,
} from "../../config/utils";
import CsvButton from "../../components/Buttons/CsvButton";
import moment from "moment/moment";
import useConstructor from "../../config/hooks/useConstructor";
import AnalysesController from "../../config/apiUtils/AnalysesController";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import Grid from "../../components/Grid";
import {
  columns,
  defaultFilterValue,
  gridStyle,
} from "../../components/Grids/Analyses/tracabilitecontrat";

export default function TracabiliteContrats() {
  const context = useContext(StoreContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getColumns = () => {
    const tmp = {};
    columns.forEach((col) => {
      tmp[col.name] = col.header;
    });
    return tmp;
  };

  const loadData = async () => {
    setLoading(true);
    setData(
      await AnalysesController.getTracabiliteContrats(
        context.millesime.idmillesime,
      ),
    );
    setLoading(false);
  };

  useConstructor(loadData);

  const onEditComplete = async ({ value, columnId, data }) => {
    if (columnId === "datereceptiontracabilite") {
      const res = await AnalysesController.updateDateReceptionTracabilite(
        data.idcontrat,
        value != null ? (value !== "" ? value : null) : null,
      );
      console.log(res);

      if (res.status === 200) {
        createNotification(
          "success",
          "Succès",
          "Date de réception de la traçabilité modifiée avec succès",
        );
      }

      await loadData();
    }
  };

  return (
    <Analyses>
      <Row>
        <Col>
          <Card>
            <CardHeader>
              <Row>
                <Col>
                  <CardTitle tag={"h5"}>Traçabilité des contrats</CardTitle>
                </Col>
                <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
                  <RenderIf isTrue={data.length > 0}>
                    <CsvButton
                      color="primary"
                      onClick={() => {
                        const name =
                          "analyses_tracabilite_contrats_" +
                          context.millesime.idmillesime +
                          "_" +
                          moment().format("YYYY-MM-DD");
                        downloadCsv(
                          convertToCSV(data, ";", getColumns()),
                          name,
                        );
                        createNotification(
                          "info",
                          "Information",
                          "Export téléchargé dans le fichier : " + name,
                        );
                      }}
                    />
                  </RenderIf>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <RenderIf isTrue={loading}>
                <SpinLoadingAnimation />
              </RenderIf>
              <RenderIf isTrue={!loading}>
                <Grid
                  enableCellSelect={true}
                  idProperty="id"
                  emptyText="Aucun contrat renseigné"
                  columns={columns}
                  dataSource={data}
                  defaultFilterValue={defaultFilterValue}
                  style={gridStyle}
                  onEditComplete={onEditComplete}
                />
              </RenderIf>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Analyses>
  );
}
