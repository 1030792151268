import {
  cellRendererDefaultBoolean,
  cellRendererSwitch,
  cellStyleDanger,
  cellStyleSuccess,
  cellStyleSwitchDefaultBoolean,
  cellStyleWarning,
  valueFormatterDate,
  valueFormatterUnite,
} from "../../../config/gridUtils";
import AuthService from "../../../config/AuthService";
import droits from "../../../config/CodesDroits";

export const columns = [
  {
    headerName: "Exploitation",
    children: [
      {
        headerName: "Raison sociale",
        field: "exploitation.raisonsociale",
      },
      {
        headerName: "SIRET",
        field: "exploitation.siret",
      },
      {
        headerName: "Statut juridique",
        field: "exploitation.libellestatutjuridique",
        columnGroupShow: "open",
      },
      {
        headerName: "Localisation",
        field: "exploitation.ville",
        valueGetter: (params: any) =>
          params.data.exploitation.codepostal
            ? `${params.data.exploitation.codepostal} ${params.data.exploitation.ville}`
            : "",
        columnGroupShow: "open",
      },
      {
        headerName: "Code OS",
        field: "exploitation.codeos",
        editable: false,
        columnGroupShow: "open",
      },
      {
        headerName: "Zones de production",
        field: "exploitation.libellezoneproduction",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Visite",
    children: [
      {
        headerName: "Type de la visite",
        field: "typeevisite",
        valueGetter: (params: any) =>
          params.data.typevisite === "initiale"
            ? "Visite initiale"
            : "Contre-visite",
      },
      {
        headerName: "Date de la visite",
        field: "datevisite",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
        cellEditor: "agDateCellEditor",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_VISITE_PARCELLES,
            droits.modalite.modification,
          ),
      },
      {
        headerName: "Conforme",
        field: "conforme",
        cellRenderer: (params: any) =>
          cellRendererSwitch(params.value, cellRendererDefaultBoolean),
        cellStyle: (params: any) =>
          cellStyleSwitchDefaultBoolean(
            params.value,
            cellStyleSuccess,
            cellStyleDanger,
            cellStyleWarning,
          ),
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_VISITE_PARCELLES,
            droits.modalite.modification,
          ),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: [
            {
              value: true,
              label: "OUI",
            },
            {
              value: false,
              label: "NON",
            },
          ],
        },
      },
      {
        headerName: "Présence de colza",
        field: "presencecolza",
        cellRenderer: (params: any) =>
          cellRendererSwitch(params.value, cellRendererDefaultBoolean),
        cellStyle: (params: any) =>
          cellStyleSwitchDefaultBoolean(
            params.value,
            cellStyleDanger,
            cellStyleSuccess,
            cellStyleWarning,
          ),
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_VISITE_PARCELLES,
            droits.modalite.modification,
          ),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: [
            {
              value: true,
              label: "OUI",
            },
            {
              value: false,
              label: "NON",
            },
          ],
        },
      },
      {
        headerName: "Nb plantes/m²",
        field: "nbcolza",
        cellDataType: "number",
        valueFormatter: (params: any) =>
          valueFormatterUnite(params, "plantes/m²"),
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_VISITE_PARCELLES,
            droits.modalite.modification,
          ),
      },
      {
        headerName: "Présence de sanves ravenelles",
        field: "presencesanvesravenelles",
        cellRenderer: (params: any) =>
          cellRendererSwitch(params.value, cellRendererDefaultBoolean),
        cellStyle: (params: any) =>
          cellStyleSwitchDefaultBoolean(
            params.value,
            cellStyleDanger,
            cellStyleSuccess,
            cellStyleWarning,
          ),
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_VISITE_PARCELLES,
            droits.modalite.modification,
          ),
        cellEditor: "customSelectCellEditor",
        cellEditorParams: {
          values: [
            {
              value: true,
              label: "OUI",
            },
            {
              value: false,
              label: "NON",
            },
          ],
        },
      },
      {
        headerName: "Nb plantes/m²",
        field: "nbsanvesravenelles",
        cellDataType: "number",
        valueFormatter: (params: any) =>
          valueFormatterUnite(params, "plantes/m²"),
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_VISITE_PARCELLES,
            droits.modalite.modification,
          ),
      },
      {
        headerName: "Autres anomalies détectées",
        field: "autresanomalies",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_VISITE_PARCELLES,
            droits.modalite.modification,
          ),
      },
      {
        headerName: "Commentaire",
        field: "commentaire",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_VISITE_PARCELLES,
            droits.modalite.modification,
          ),
        cellEditor: "agLargeTextCellEditor",
        cellEditorPopup: true,
      },
      {
        headerName: "Date de la prochaine visite prévue",
        field: "datevisitesupplementaire",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
        cellEditor: "agDateCellEditor",
        editable:
          AuthService.isLoggedIn() &&
          AuthService.hasDroit(
            droits.typesdroits.GESTION_VISITE_PARCELLES,
            droits.modalite.modification,
          ),
      },
    ],
  },
  {
    headerName: "Technicien",
    children: [
      {
        headerName: "Nom",
        field: "nomtechnicien",
        cellRenderer: (params: any) =>
          params.data.nomtechnicien + " " + params.data.prenomtechnicien,
        columnGroupShow: "closed",
      },
      {
        headerName: "Nom",
        field: "nomtechnicien",
        columnGroupShow: "open",
      },
      {
        headerName: "Prénom",
        field: "prenomtechnicien",
        columnGroupShow: "open",
      },
    ],
  },
];
