import React, { useContext, useState } from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import useConstructor from "src/config/hooks/useConstructor";
import VisiteParcellesController from "src/config/apiUtils/visiteParcellesController";
import StoreContext from "src/context/StoreContext";
import { IVisitesParcelle } from "src/config/types/visiteparcelles";
import AgGrid from "src/components/AgGrid";
import { columns } from "./grid/ListeParcelles";
import moment from "moment";
import ModalFicheVisite from "./ModalFicheVisite";
import { RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";

export default function ListeParcelles(): React.JSX.Element {
  const context = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [visites, setVisites] = useState<IVisitesParcelle[]>([]);
  const [visiteToShow, setVisiteToShow] = useState<IVisitesParcelle | null>(
    null,
  );

  const loadData = async () => {
    setLoading(true);
    const res = await VisiteParcellesController.getVisiteParcelles(
      context.millesime.idmillesime,
    );
    res.forEach((e: IVisitesParcelle) => {
      if (e.visites.length > 0) {
        // Trouver la visite avec la date la plus récente
        const visiteRecente = e.visites.reduce((latest, current) => {
          return moment(current.datevisite).isAfter(moment(latest.datevisite))
            ? current
            : latest;
        });
        e.datedernierevisite = visiteRecente.datevisite;
      } else {
        e.datedernierevisite = null;
      }
      e.nbvisites = e.visites.length;
    });
    setVisites(res);
    setLoading(false);
  };

  useConstructor(async () => {
    await loadData();
  });

  const onRowClick = (params: any) => {
    setVisiteToShow(params.data);
  };

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col>
            <CardTitle tag="h5">Liste des parcelles</CardTitle>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <RenderIf isTrue={loading}>
              <SpinLoadingAnimation />
            </RenderIf>
            <RenderIf isTrue={!loading}>
              <AgGrid
                rowData={visites}
                columnDefs={columns}
                showFilterLine
                onRowClicked={onRowClick}
              />
            </RenderIf>
            <RenderIf isTrue={visiteToShow !== null}>
              <ModalFicheVisite
                visiteParcelle={visiteToShow}
                isOpen={visiteToShow !== null}
                onClose={async () => {
                  setVisiteToShow(null);
                  await loadData();
                }}
              />
            </RenderIf>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
