import { valueFormatterDate } from "../../../config/gridUtils";

export const columns = [
  {
    headerName: "Exploitation",
    children: [
      {
        headerName: "Raison sociale",
        field: "exploitation.raisonsociale",
      },
      {
        headerName: "SIRET",
        field: "exploitation.siret",
      },
      {
        headerName: "Statut juridique",
        field: "exploitation.libellestatutjuridique",
        columnGroupShow: "open",
      },
      {
        headerName: "Localisation",
        field: "exploitation.ville",
        valueGetter: (params: any) =>
          params.data.exploitation.codepostal
            ? `${params.data.exploitation.codepostal} ${params.data.exploitation.ville}`
            : "",
        columnGroupShow: "open",
      },
      {
        headerName: "Code OS",
        field: "exploitation.codeos",
        editable: false,
        columnGroupShow: "open",
      },
      {
        headerName: "Zones de production",
        field: "exploitation.libellezoneproduction",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Parcelle",
    children: [
      {
        headerName: "Nom de la parcelle",
        field: "parcelle.nomparcelle",
      },
      {
        headerName: "Localisation parcelle",
        field: "parcelle.nomcommune",
        valueGetter: (params: any) =>
          params.data.parcelle.codepostal
            ? `${params.data.parcelle.codepostal} ${params.data.parcelle.nomcommune}`
            : "",
        columnGroupShow: "open",
      },
      {
        headerName: "Type de sol",
        field: "parcelle.libelletypesol",
        columnGroupShow: "open",
      },
      {
        headerName: "Culture",
        field: "parcelle.libelleculture",
        columnGroupShow: "open",
      },
    ],
  },
  {
    headerName: "Visite",
    children: [
      {
        headerName: "Nombre de visites",
        field: "nbvisites",
        cellDataType: "number",
      },
      {
        headerName: "Date de la dernière visite",
        field: "datedernierevisite",
        cellDataType: "date",
        valueFormatter: valueFormatterDate,
      },
    ],
  },
];
