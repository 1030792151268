import React from "react";
import APropos from "../../views/APropos";
import ChangeExploitation from "../../views/ChangeExploitation";
import ContactPage from "../../views/ContactPage";
import Dashboard from "../../views/Dashboard";
import Documentation from "../../views/Documentation";
import MentionsLegales from "../../views/MentionsLegales";
import MonCompte from "../../views/MonCompte";
import Page404 from "../../views/Page404";
import PrivacyPolicy from "../../views/PrivacyPolicy";
import Referentiels from "../../views/Referentiels";
import Version from "../../views/Version";

import droits from "../CodesDroits";
import Layouts from "../layoutRoutes";

const UserRoutes = [
  {
    path: "/dashboard",
    name: "Tableau de bord",
    element: <Dashboard />,
    layout: Layouts.userLayout,
    show: true,
  },
  {
    path: "/referentiels",
    name: "Référentiels",
    element: <Referentiels />,
    restriction: droits.typesdroits.ACCES_REFERENTIEL,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/change-exploitation",
    name: "Changer d'exploitation",
    element: <ChangeExploitation />,
    restriction: droits.typesdroits.ACCES_EXPLOITATION,
    layout: Layouts.userLayout,
    show: true,
  },
  {
    path: "/myaccount",
    name: "Mon compte",
    element: <MonCompte />,
    layout: Layouts.userLayout,
    show: true,
  },
  {
    path: "/versions",
    name: "Versions",
    element: <Version />,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/apropos",
    name: "A propos",
    element: <APropos />,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/contact",
    name: "Contact",
    element: <ContactPage />,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/documentation",
    name: "Documentation",
    element: <Documentation />,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/legal",
    name: "Mentions légales",
    element: <MentionsLegales />,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/privacy-policy",
    name: "Politique de confidentialité",
    element: <PrivacyPolicy />,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
  {
    path: "/404",
    name: "404",
    element: <Page404 />,
    layout: Layouts.userLayout,
    show: true,
    pageGenerique: true,
  },
];
export default UserRoutes;
