import React, { useState } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import moment from "moment/moment";
import ValidateButton from "../../../components/Buttons/ValidateButton";
import CancelButton from "../../../components/Buttons/CancelButton";
import { IVisite, IVisitePOST } from "../../../config/types/visiteparcelles";
import { IParcelle } from "../../../config/types/parcelle";
import VisiteParcellesController from "../../../config/apiUtils/visiteParcellesController";
import { createNotification } from "../../../config/utils";

interface IProps {
  parcelle: IParcelle;
  close: (newVisite: IVisite[] | null | undefined) => void;
}

export default function FormNewVisite(props: IProps): React.JSX.Element {
  const [loading, setLoading] = useState<boolean>(false);

  const [presenceColza, setPresenceColza] = useState<boolean>(false);
  const [nbColza, setNbColza] = useState<number | null>(null);
  const [nbColzaInput, setNbColzaInput] = useState<string>("");

  const [presenceSanves, setPresenceSanve] = useState<boolean>(false);
  const [nbSanves, setNbSanves] = useState<number | null>(null);
  const [nbSanvesInput, setNbSanvesInput] = useState<string>("");

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setLoading(true);
    const data: IVisitePOST = {
      idparcelle: props.parcelle.idparcelle,
      actioncorrectivemiseplace:
        event.target.actioncorrectivemiseplace.value !== ""
          ? event.target.actioncorrectivemiseplace.value
          : null,
      actioncorrectiveproposee:
        event.target.actioncorrectiveproposee.value !== ""
          ? event.target.actioncorrectiveproposee.value
          : null,
      autresanomalies:
        event.target.autresanomalies.value !== ""
          ? event.target.autresanomalies.value
          : null,
      commentaire:
        event.target.commentaire.value !== ""
          ? event.target.commentaire.value
          : null,
      conforme: event.target.conforme.value == 1,
      datevisite:
        event.target.datevisite.value !== ""
          ? event.target.datevisite.value
          : null,
      datevisitesupplementaire:
        event.target.datevisitesupplementaire.value !== ""
          ? event.target.datevisitesupplementaire.value
          : null,
      nbcolza: nbColza,
      nbsanvesravenelles: nbSanves,
      presencecolza: presenceColza,
      presencesanvesravenelles: presenceSanves,
      conformeapresaction: event.target.conformeapresaction.value == 1,
    };

    const res = await VisiteParcellesController.insertVisiteParcelle(data);

    if (res.status === 200) {
      createNotification("success", "Succès", "Visite renseignée avec succès");
      props.close(res.data);
    }

    setLoading(false);
  };

  const handleNumericInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    setInputValue: (value: string) => void,
    setNumericValue: (value: number | null) => void,
  ) => {
    const value = e.target.value;

    // Autorise uniquement les chiffres avec un point ou une virgule
    if (/^[0-9]*[.,]?[0-9]*$/.test(value) || value === "") {
      setInputValue(value);

      // Si le champ est vide, on remet la valeur numérique à null
      if (value === "") {
        setNumericValue(null);
      } else {
        // Conversion : remplace la virgule par un point
        const normalizedValue = value.replace(",", ".");

        // Vérifie que la valeur n'est pas juste un séparateur
        if (
          !isNaN(Number(normalizedValue)) &&
          !normalizedValue.endsWith(".") &&
          !normalizedValue.endsWith(",")
        ) {
          setNumericValue(Number(normalizedValue));
        } else {
          setNumericValue(null); // Si la saisie est incomplète, on garde null
        }
      }
    }
  };

  return (
    <div id={"form-new-visite"}>
      <h3>Nouvelle visite</h3>
      <Form onSubmit={onSubmit}>
        <Row style={{ marginBottom: 10 }}>
          <Col>
            <FormGroup>
              <Label for="datevisite">Date de la visite</Label>
              <Input
                type="date"
                name="datevisite"
                defaultValue={moment().format("YYYY-MM-DD")}
                required
              ></Input>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col>
            <h5>Conformité de la parcelle</h5>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col md={6}>
            <FormGroup>
              <Label for="nbcolza">
                <FormGroup>
                  <Label check style={{ display: "flex" }}>
                    <Input
                      type="checkbox"
                      onClick={(event: any) => {
                        setPresenceColza(event.target.checked);
                        if (!event.target.checked) {
                          setNbColza(null);
                          setNbColzaInput("");
                        }
                      }}
                    />
                    &nbsp;Présence de colza
                  </Label>
                </FormGroup>
              </Label>
              <Input
                type={"text"}
                name="nbcolza"
                style={{ marginBottom: 20 }}
                placeholder={"plantes de colza / m²"}
                disabled={!presenceColza}
                required={presenceColza}
                value={nbColzaInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleNumericInput(e, setNbColzaInput, setNbColza)
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="nbsanvesravenelles">
                <FormGroup>
                  <Label check style={{ display: "flex" }}>
                    <Input
                      type="checkbox"
                      onClick={(event: any) => {
                        setPresenceSanve(event.target.checked);
                        if (!event.target.checked) {
                          setNbSanves(null);
                          setNbSanvesInput("");
                        }
                      }}
                    />
                    &nbsp;Présence de sanves ravenelles
                  </Label>
                </FormGroup>
              </Label>
              <Input
                type={"text"}
                name="nbsanvesravenelles"
                style={{ marginBottom: 20 }}
                placeholder={"Plantes de sanves ravenelles / m²"}
                disabled={!presenceSanves}
                required={presenceSanves}
                value={nbSanvesInput}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleNumericInput(e, setNbSanvesInput, setNbSanves)
                }
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col md={6}>
            <FormGroup>
              <Label for="conforme">Conforme</Label>
              <Input
                name="conforme"
                type="select"
                className="select-input"
                defaultValue={0}
              >
                <option value={1}>OUI</option>
                <option value={0}>NON</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="actioncorrectiveproposee">
                Action corrective proposée
              </Label>
              <Input
                type={"text"}
                name={"actioncorrectiveproposee"}
                placeholder={"Action corrective proposée"}
              ></Input>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label for="actioncorrectivemiseplace">
                Action corrective mise en place
              </Label>
              <Input
                type={"text"}
                name={"actioncorrectivemiseplace"}
                placeholder={"Action corrective mise en place"}
              ></Input>
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for="conformeapresaction">Conforme après action</Label>
              <Input
                name="conformeapresaction"
                type="select"
                className="select-input"
                defaultValue={0}
              >
                <option value={1}>OUI</option>
                <option value={0}>NON</option>
              </Input>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col>
            <FormGroup>
              <Label for="datevisitesupplementaire">
                Date de prochaine la visite
              </Label>
              <Input
                type="date"
                name="datevisitesupplementaire"
                defaultValue={moment().format("YYYY-MM-DD")}
              ></Input>
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col md={6}>
            <FormGroup>
              <Label for={"autresanomalies"}>Autres anomalies</Label>
              <Input
                name="autresanomalies"
                type="textarea"
                placeholder="Autres anomalies..."
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label for={"commentaire"}>Commentaire</Label>
              <Input
                name="commentaire"
                type="textarea"
                placeholder="Commentaire..."
              />
            </FormGroup>
          </Col>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <Col>
            <ValidateButton style={{ marginRight: 10 }} />
            <CancelButton
              onClick={(event: any) => {
                event.preventDefault();
                props.close(null);
              }}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
}
