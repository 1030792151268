import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import CloseButton from "../../components/Buttons/CloseButton";
import StoreContext from "../../context/StoreContext";
import useConstructor from "../../config/hooks/useConstructor";
import ContratController from "../../config/apiUtils/ContratController";
import ReferentielController from "../../config/apiUtils/ReferentielController";
import ValidateButton from "../../components/Buttons/ValidateButton";
import CancelButton from "../../components/Buttons/CancelButton";
import { createNotification, GoToTopPage, RenderIf } from "../../config/utils";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import OrganismeStockeurController from "../../config/apiUtils/OrganismeStockeurController";
import Constantes from "../../config/constantes";

export default function FormUpdateContrat(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);
  const [validationadministrative, setValidationadministrative] = useState(
    props.contrat.valide,
  );
  const [idetatvalidationadministrative, setIdetatvalidationadministrative] =
    useState(props.contrat.idetatvalidationadministrative);

  useEffect(() => {
    setIdetatvalidationadministrative(
      props.contrat.idetatvalidationadministrative,
    );
    if (props.contrat.valide) {
      setValidationadministrative(props.contrat.valide);
    } else {
      if (store.utilisateur.profilsUtilisateur[0].estorganismestockeur) {
        if (
          props.contrat.idetatvalidationadministrative ==
          Constantes.etatsValidationAdministrative.valideOS
        ) {
          setValidationadministrative(true);
        } else {
          setValidationadministrative(false);
        }
      } else {
        if (
          props.contrat.idetatvalidationadministrative ==
          Constantes.etatsValidationAdministrative.valideAPGMB
        ) {
          setValidationadministrative(true);
        } else {
          setValidationadministrative(false);
        }
      }
    }
  }, [props.contrat]);

  useConstructor(async () => {
    GoToTopPage();
    setLoading(true);
    store.referentiels.setTypesProduction(
      await ReferentielController.getTypesProduction(true),
    );
    store.setOrganismesStockeur(
      await OrganismeStockeurController.getOrganismesStockeur(),
    );
    store.referentiels.setEtatsValidationAdministrative(
      await ReferentielController.getEtatsValidationAdministrative(),
    );
    setLoading(false);
  });

  const onSubmit = async (event) => {
    event.preventDefault();

    const contrat = {
      datecontrat: event.target.datecontrat.value,
      dateenvoifacturecotisation:
        event.target.dateenvoifacturecotisation.value == ""
          ? null
          : event.target.dateenvoifacturecotisation.value,
      dateenvoifacturerelance:
        event.target.dateenvoifacturerelance.value == ""
          ? null
          : event.target.dateenvoifacturerelance.value,
      datereceptiontracabilite:
        event.target.datereceptiontracabilite.value == ""
          ? null
          : event.target.datereceptiontracabilite.value,
      idreftypeproduction: event.target.idreftypeproduction.value,
      idorganismestockeur: event.target.idorganismestockeur.value,
      nbparcelles: event.target.nbparcelles.value,
      abandon: event.target.abandon.value,
      commentaireabandon: event.target.commentaireabandon.value,
      idetatvalidationadministrative: idetatvalidationadministrative,
      valide: validationadministrative,
      surfaceprintemps: event.target.surfaceprintemps.value,
      surfaceautomne: event.target.surfaceautomne.value,
      commentaire: event.target.commentaire.value,
    };

    const res = await ContratController.updateContrat(
      props.contrat.idcontrat,
      contrat,
    );
    if (res.status === 200) {
      createNotification("success", "Succès", "Contrat modifié avec succès");
      const tmp = [...store.contrats];
      tmp.forEach((contrat, i) => {
        if (contrat.idcontrat === props.contrat.idcontrat) {
          tmp[i] = res.data;
        }
      });
      store.setContrats(tmp);
      props.onClickClose();
    } else {
      createNotification("error", "Erreur", res.data.detail);
    }
  };

  const renderTypesProductionOptions = () => {
    return store.referentiels.typesProduction.map((type, key) => {
      return (
        <option key={key} value={type.idreftypeproduction}>
          {type.libelle}
        </option>
      );
    });
  };

  const renderEtatsValidationAdministrativeOptions = () => {
    return store.referentiels.etatsValidationAdministrative.map((etat, key) => {
      return (
        <option key={key} value={etat.idetatvalidationadministrative}>
          {etat.libelle}
        </option>
      );
    });
  };

  const renderOrganismesStockeurOptions = () => {
    return store.organismesStockeur.map((organisme, key) => {
      return (
        <option key={key} value={organisme.idorganismestockeur}>
          {organisme.nomorganismestockeur}
        </option>
      );
    });
  };

  return (
    <>
      <Card>
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>
                Fiche contrat : {props.contrat.numerocontrat}
              </CardTitle>
            </Col>
            <Col style={{ display: "flex", flexDirection: "row-reverse" }}>
              <CloseButton onClick={props.onClickClose} />
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Form onSubmit={onSubmit}>
              <Row style={{ marginBottom: 10 }}>
                <Col md="3">
                  <Label for="raisonsociale">Exploitation</Label>
                  <Input
                    type="text"
                    name="raisonsociale"
                    value={props.contrat.raisonsociale}
                    onChange={() => {}}
                    readOnly
                    disabled
                  />
                </Col>
                <Col md="3">
                  <Label for="idorganismestockeur">Organisme stockeur</Label>
                  <Input
                    type="select"
                    className="select-input"
                    name="idorganismestockeur"
                    defaultValue={props.contrat.idorganismestockeur}
                    disabled={
                      (store.utilisateur.profilsUtilisateur[0]
                        .estorganismestockeur &&
                        (props.contrat.idetatvalidationadministrative === 3 ||
                          props.contrat.idetatvalidationadministrative ===
                            4)) ||
                      props.contrat.idetatvalidationadministrative === 4
                    }
                  >
                    {renderOrganismesStockeurOptions()}
                  </Input>
                </Col>
                <Col md="2">
                  <Label for="dateenvoifacturerelance">
                    Date d'envoi de la facture de pénalité
                  </Label>
                  <Input
                    type="date"
                    name="dateenvoifacturerelance"
                    defaultValue={props.contrat.dateenvoifacturerelance}
                  />
                </Col>
                <Col md="2">
                  <Label for="dateenvoifacturecotisation">
                    Date d'envoi de la facture de cotisation
                  </Label>
                  <Input
                    type="date"
                    name="dateenvoifacturecotisation"
                    defaultValue={props.contrat.dateenvoifacturecotisation}
                  />
                </Col>
                <Col md="2">
                  <Label for="numerocontrat">Date du contrat</Label>
                  <Input
                    type="date"
                    name="datecontrat"
                    defaultValue={props.contrat.datecontrat}
                    disabled={
                      (store.utilisateur.profilsUtilisateur[0]
                        .estorganismestockeur &&
                        (props.contrat.idetatvalidationadministrative === 3 ||
                          props.contrat.idetatvalidationadministrative ===
                            4)) ||
                      props.contrat.idetatvalidationadministrative === 4
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col md="3">
                  <Label for="nbparcelles">Nombre de parcelles</Label>
                  <Input
                    type="text"
                    name="nbparcelles"
                    defaultValue={props.contrat.nbparcelles}
                    disabled={
                      (store.utilisateur.profilsUtilisateur[0]
                        .estorganismestockeur &&
                        (props.contrat.idetatvalidationadministrative === 3 ||
                          props.contrat.idetatvalidationadministrative ===
                            4)) ||
                      props.contrat.idetatvalidationadministrative === 4
                    }
                  />
                </Col>
                <Col md="3">
                  <Label for="idreftypeproduction">Type de production</Label>
                  <Input
                    name="idreftypeproduction"
                    type="select"
                    className="select-input"
                    defaultValue={props.contrat.idreftypeproduction}
                    disabled={
                      (store.utilisateur.profilsUtilisateur[0]
                        .estorganismestockeur &&
                        (props.contrat.idetatvalidationadministrative === 3 ||
                          props.contrat.idetatvalidationadministrative ===
                            4)) ||
                      props.contrat.idetatvalidationadministrative === 4
                    }
                  >
                    {renderTypesProductionOptions()}
                  </Input>
                </Col>
                <Col md="2">
                  <Label for="surfaceprintemps">
                    Surface engagée au printemps (ha)
                  </Label>
                  <Input
                    type="text"
                    name="surfaceprintemps"
                    defaultValue={props.contrat.surfaceprintemps}
                    disabled={
                      (store.utilisateur.profilsUtilisateur[0]
                        .estorganismestockeur &&
                        (props.contrat.idetatvalidationadministrative === 3 ||
                          props.contrat.idetatvalidationadministrative ===
                            4)) ||
                      props.contrat.idetatvalidationadministrative === 4
                    }
                  />
                </Col>
                <Col md="2">
                  <Label for="surfaceautomne">
                    Surface engagée en automne (ha)
                  </Label>
                  <Input
                    type="text"
                    name="surfaceautomne"
                    defaultValue={props.contrat.surfaceautomne}
                    disabled={
                      (store.utilisateur.profilsUtilisateur[0]
                        .estorganismestockeur &&
                        (props.contrat.idetatvalidationadministrative === 3 ||
                          props.contrat.idetatvalidationadministrative ===
                            4)) ||
                      props.contrat.idetatvalidationadministrative === 4
                    }
                  />
                </Col>
                <Col md="2">
                  <Label for="datereceptiontracabilite">
                    Date de réception de la traçabilité
                  </Label>
                  <Input
                    type="date"
                    name="datereceptiontracabilite"
                    defaultValue={props.contrat.datereceptiontracabilite}
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <RenderIf
                  isTrue={
                    store.utilisateur.profilsUtilisateur[0].codeprofil ===
                    "ADMINISTRATEUR"
                  }
                >
                  <Col md="4">
                    <Label for="idetatvalidationadministrative">
                      Validation administrative
                    </Label>
                    <Input
                      type="select"
                      className="select-input"
                      name="idetatvalidationadministrative"
                      defaultValue={
                        props.contrat.idetatvalidationadministrative
                      }
                      onChange={(e) =>
                        setIdetatvalidationadministrative(e.target.value)
                      }
                    >
                      {renderEtatsValidationAdministrativeOptions()}
                    </Input>
                  </Col>
                </RenderIf>
                <Col md="2">
                  <Label for="abandon">Contrat abandonné</Label>
                  <Input
                    name="abandon"
                    type="select"
                    className="select-input"
                    defaultValue={props.contrat.abandon}
                    disabled={
                      store.utilisateur.profilsUtilisateur[0]
                        .estorganismestockeur &&
                      (props.contrat.idetatvalidationadministrative === 3 ||
                        props.contrat.idetatvalidationadministrative === 4)
                    }
                  >
                    <option value={true}>OUI</option>
                    <option value={false}>NON</option>
                  </Input>
                </Col>
                <Col
                  md={
                    store.utilisateur.profilsUtilisateur[0].codeprofil ===
                    "ADMINISTRATEUR"
                      ? "6"
                      : "8"
                  }
                >
                  <Label for="commentaireabandon">Raison abandon</Label>
                  <Input
                    name="commentaireabandon"
                    type="textarea"
                    placeholder="Raison de l'abandon"
                    defaultValue={props.contrat.commentaireabandon}
                    disabled={
                      store.utilisateur.profilsUtilisateur[0]
                        .estorganismestockeur &&
                      (props.contrat.idetatvalidationadministrative === 3 ||
                        props.contrat.idetatvalidationadministrative === 4)
                    }
                  />
                </Col>
              </Row>
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  <Label for={"commentaire"}>Commentaire</Label>
                  <Input
                    name="commentaire"
                    type="textarea"
                    placeholder="Commentaire"
                    defaultValue={props.contrat.commentaire}
                  />
                </Col>
              </Row>
              <RenderIf
                isTrue={
                  store.utilisateur.profilsUtilisateur[0].codeprofil !==
                  "ADMINISTRATEUR"
                }
              >
                <Row style={{ marginBottom: 10 }}>
                  <Col>
                    <Label check>
                      <Input
                        type="checkbox"
                        name="validationadministrative"
                        checked={validationadministrative}
                        onChange={(e) =>
                          setValidationadministrative(e.target.checked)
                        }
                      />{" "}
                      Validé administrativement
                    </Label>
                  </Col>
                </Row>
              </RenderIf>
              <Row style={{ marginBottom: 10 }}>
                <Col>
                  <ValidateButton style={{ marginRight: 10 }} />
                  <CancelButton
                    style={{ marginRight: 10 }}
                    onClick={() => props.onClickClose()}
                  />
                </Col>
              </Row>
            </Form>
          </RenderIf>
        </CardBody>
      </Card>
    </>
  );
}
