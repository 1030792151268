import ol_format_GEOJSON from "ol/format/GeoJSON";
import { createEmpty, extend } from "ol/extent";
import ol_layer_Tile from "ol/layer/Tile";
import ol_layer_Vector from "ol/layer/Vector";
import ol_source_OSM from "ol/source/OSM";
import ol_source_Vector from "ol/source/Vector";
import ol_proj_Projection from "ol/proj/Projection";
import { Circle, Fill, Stroke, Style, Text } from "ol/style";
import { bbox as bboxStrategy } from "ol/loadingstrategy";
import ButtonLayer from "../../components/carto/ButtonLayer";
import { getSurface } from "./utils";
import Draw from "ol/interaction/Draw";
import Modify from "ol/interaction/Modify";
import { MultiPoint } from "ol/geom";

export default class Layer {
  static catalogue = {
    PARCELLAIRE_EXPLOITATION: "PARCELLAIRE_EXPLOITATION",
    APPARIEMENT_PARCELLES: "APPARIEMENT_PARCELLES",
  };

  static codeCouche = {
    FONDS_PAC: "FONDS_PAC",
    FONDS_IGN: "FONDS_IGN",
    RPG_ANONYME: "RPG_ANONYME",
    COMMUNES: "COMMUNES",
    DEPARTEMENTS: "DEPARTEMENTS",
    PARCELLAIRE_EXPLOITATION: "PARCELLAIRE_EXPLOITATION",
    FONDS_OSM: "FONDS_OSM",
    APPARIEMENT_PARCELLES_ASSOCIEES: "APPARIEMENT_PARCELLES_ASSOCIEES",
  };
  static typeLayer = {
    WMS: 1,
    TMS: 2,
    WMTS: 3,
    WMS_VECTOR: 4,
    METIER: 5,
    WFS: 6,
    GEOJSON: 7,
    OSM: 8,
  };
  constructor(
    {
      idcouchecarto,
      idtypecouchecarto,
      codecouche,
      extension,
      format,
      icone,
      url,
      layer,
      libelle,
      description,
      libelletypecouche,
      srs,
      ordre,
      style,
      visible,
      attribution,
      labels,
      afficherlabel,
    },
    mapRef,
    forceUpdate,
  ) {
    this.extent = [-357824.0, 6037007.0, 1313633.0, 7230727.0];
    this.projection = new ol_proj_Projection({
      code: "EPSG:2154",
    });
    this.info = {
      idcouchecarto,
      idtypecouchecarto,
      codecouche,
      extension,
      format,
      icone,
      url,
      layer,
      libelle,
      description,
      libelletypecouche,
      srs,
      ordre,
      style,
      visible,
      attribution,
      labels,
      opacity: visible ? 1 : 0,
      textFeatureShown: afficherlabel,
    };

    this.mapRef = mapRef;
    this.forceUpdate = forceUpdate;

    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.enableFeatureText = this.enableFeatureText.bind(this);
    this.hideLayer = this.hideLayer.bind(this);
    this.showLayer = this.showLayer.bind(this);
    this.setOpacity = this.setOpacity.bind(this);
    this.styleFunction = this.styleFunction.bind(this);
    this.clearFeatureDrawn = this.clearFeatureDrawn.bind(this);

    this.selectable = false;
    this.selected = [];

    this.loadLayer();
  }

  constructFeatureLabel(feature) {
    if (this.info.labels[0].label.length === 0) {
      return;
    }
    let finalLabel = "";
    this.info.labels[0].label.forEach((label) => {
      const action = label.split("::");
      if (action[0])
        switch (action[0]) {
          case "text":
            finalLabel += action[1];
            break;
          case "feature":
            finalLabel += feature.get(action[1]) ?? "-";
            break;
          case "function":
            switch (action[1]) {
              case "getSurface":
                finalLabel += getSurface(feature.getGeometry());
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
    });
    return finalLabel;
  }

  initDrawer(onDrawStart, onDrawEnd) {
    this.drawer = new Draw({
      type: "Polygon",
    });
    this.isDrawing = false;
    this.featureDrawn = null;
    this.drawer.on("drawstart", (evt) => {
      this.clearFeatureDrawn();
      if (onDrawStart) {
        onDrawStart(evt);
      }
    });
    this.drawer.on("drawend", (evt) => {
      this.featureDrawn = evt.feature;
      onDrawEnd(evt);
    });
  }

  clearFeatureDrawn() {
    this.featureDrawn = null;
  }

  removeLastPoint() {
    this.drawer.removeLastPoint();
  }

  draw() {
    this.isDrawing = true;
    this.mapRef.current.addInteraction(this.drawer);
  }

  stopDrawing() {
    this.isDrawing = false;
    this.mapRef.current.removeInteraction(this.drawer);
  }

  modifyFeature(feature) {
    this.modifier = new Modify({
      source: new ol_source_Vector({
        features: [feature],
      }),
    });
    feature.set("modifying", true);
    this.couche.setStyle(this.styleFunction);
    this.mapRef.current.addInteraction(this.modifier);
  }

  stopModify() {
    this.mapRef.current.removeInteraction(this.modifier);
    this.modifier = null;
    this.couche
      .getSource()
      .getFeatures()
      .forEach((feature) => {
        feature.setStyle(null);
        feature.set("modifying", null);
      });
    this.couche.setStyle(this.styleFunction);
  }

  isFeatureInLayer(feature) {
    return this.couche.getSource().getFeatures().includes(feature);
  }

  setSelectable(selectable, onSelectEvent) {
    this.selectable = selectable;
    this.onSelectEvent = onSelectEvent;
  }

  selectFeature(feature) {
    this.selected.push(feature);
    this.couche.getSource().changed();
  }

  isVisible() {
    return this.info.visible;
  }

  unselectFeature(feature) {
    this.selected = [...this.selected].filter((f) => f != feature);
    this.couche.getSource().changed();
  }

  toggleSelectionFeature(feature) {
    if (this.selected.includes(feature)) {
      this.unselectFeature(feature);
    } else {
      this.selectFeature(feature);
    }
  }

  clearSelection() {
    this.selected = [];
    this.couche.getSource().changed();
  }

  hideLayer() {
    this.info.visible = false;
    this.info.opacity = 0;
    this.couche.setVisible(false);
    this.couche.setOpacity(0);
  }

  showLayer(opacity = 1) {
    this.info.visible = true;
    this.info.opacity = opacity;
    this.couche.setVisible(true);
    this.couche.setOpacity(opacity);
  }

  toggleVisibility() {
    if (!this.info.visible) {
      this.showLayer();
    } else {
      this.hideLayer();
    }
    this.forceUpdate();
  }

  setOpacity(opacity) {
    this.couche.setOpacity(opacity);
    if (opacity > 0) {
      this.showLayer(opacity);
    }
    if (opacity === 0) {
      this.hideLayer();
    }
  }

  getButtonLayer() {
    return (
      <ButtonLayer
        background={require(`../../assets/img/carto/${this.info.icone}`)}
        name={this.info.libelle}
        tooltip={this.info.description}
        toggleVisibility={this.toggleVisibility}
        visible={this.info.visible}
        opacity={this.info.opacity}
        setOpacity={this.setOpacity}
        attribution={this.info.attribution}
        textFeatureShown={this.info.textFeatureShown}
        toggleTextFeatureShown={this.enableFeatureText}
      />
    );
  }

  styleCoordinates() {
    return new Style({
      image: new Circle({
        radius: 5,
        fill: new Fill({
          color: "orange",
        }),
      }),
      geometry: function (feature) {
        const geom = feature.getGeometry();
        if (geom.getType() === "MultiPolygon") {
          return new MultiPoint(
            geom.getPolygons().map((p) => p.getCoordinates()[0])[0],
          );
        }
        return new MultiPoint(geom.getCoordinates()[0]);
      },
    });
  }

  styleFunction(feature) {
    const style = new Style({
      stroke: new Stroke({
        color: this.info.style.couleurligne,
        width: this.info.style.tailleligne,
      }),
      fill: new Fill({
        color: this.selected.includes(feature)
          ? "#ffe682cc"
          : this.info.style.couleurfond,
      }),
      text: new Text({
        font: `${this.info.style.tailletexte}px "Roboto", "Arial Unicode MS", "Open Sans", "sans-serif"`,
        fill: new Fill({
          color: this.info.style.couleurtexte,
        }),
        stroke: new Stroke({
          color: "#fff",
          width: 2,
        }),
        overflow: true,
      }),
    });

    if (this.info.textFeatureShown) {
      style.getText().setText(this.constructFeatureLabel(feature));
    }
    const res = [style];
    if (this.modifier != null && feature.get("modifying")) {
      res.push(this.styleCoordinates());
    }
    return res;
  }

  enableFeatureText(textFeatureShown) {
    this.info.textFeatureShown = textFeatureShown;
    this.couche.setStyle(this.styleFunction);
  }

  loadLayer() {
    let couche;

    switch (this.info.idtypecouchecarto) {
      case Layer.typeLayer.WMTS:
        couche = new ol_layer_Tile({
          source: new ol_source_OSM({
            url: `${this.info.url}&LAYER=${this.info.layer}&FORMAT=${this.info.format}`,
            attribution: this.info.attribution,
          }),
          id: this.info.codecouche,
        });
        break;

      case Layer.typeLayer.OSM: {
        const source = new ol_source_OSM({
          url: `${this.info.url}.${this.info.extension}`,
          attribution: this.info.attribution,
          cacheSize: 4096,
          tilePixelRatio: 1,
          wrapX: false,
        });
        couche = new ol_layer_Tile({
          source: source,
          id: this.info.codecouche,
          cacheSize: 4096,
          preload: Infinity,
          transition: 0,
        });
        break;
      }

      case Layer.typeLayer.GEOJSON:
        couche = new ol_layer_Vector({
          source: new ol_source_Vector({
            format: new ol_format_GEOJSON(),
            url: (extent) =>
              `${this.info.url}&typeName=${this.info.layer}&outputFormat=${
                this.info.format
              }&srsname=${this.info.srs}&bbox=${extent.join(",")},${
                this.info.srs
              }`,
            strategy: bboxStrategy,
          }),
          style: this.styleFunction,
          id: this.info.codecouche,
          updateWhileInteracting: false, // Désactive les mises à jour en temps réel
          updateWhileAnimating: false, // Empêche aussi les requêtes pendant les animations
        });
        break;

      case Layer.typeLayer.METIER:
        couche = new ol_layer_Vector({
          source: new ol_source_Vector({
            features: [],
          }),
          style: this.styleFunction,
          id: this.info.codecouche,
          updateWhileInteracting: true,
        });
        break;
    }

    this.couche = couche;
    this.couche.setVisible(this.info.visible);
    this.couche.setOpacity(this.info.opacity);
  }

  getLayer() {
    return this.couche;
  }

  getSource() {
    return this.couche.getSource();
  }

  getFeatures() {
    return this.couche.getSource().getFeatures();
  }

  addFeature(feature) {
    this.couche.getSource().addFeature(feature);
  }

  removeFeature(feature) {
    this.couche.getSource().removeFeature(feature);
  }

  getCodeCouche() {
    return this.info.codecouche;
  }

  addFeatures(features) {
    this.couche.getSource().addFeatures(features);
  }
}
