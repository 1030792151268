import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { raisonsociale } from "../columns";
import { dateFilterEditorProps } from "../../../config/utils";
import colors from "../../../config/colors";
import AuthService from "../../../config/AuthService";
import DateEditor from "@inovua/reactdatagrid-community/DateEditor";
import droits from "../../../config/CodesDroits";

export const columns = [
  {
    header: "Numéro SIRET",
    name: "siret",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code OS",
    name: "codeos",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code DC",
    name: "codedc",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  raisonsociale,
  {
    header: "Nom",
    name: "nom",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Prénom",
    name: "prenom",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Adresse",
    name: "adresse",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Code postal",
    name: "codepostal",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Commune",
    name: "nomcommune",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Traçabilité reçue",
    name: "tracabiliterecue",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
        { id: null, label: "Non renseigné" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color;
      return "NON";
    },
  },
  {
    header: "Date de réception de la traçabilité",
    name: "datereceptiontracabilite",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    editable:
      AuthService.isLoggedIn() &&
      AuthService.hasDroit(
        droits.typesdroits.TRACABILITE_CONTRATS,
        droits.modalite.modification,
      ),
    editor: DateEditor,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
    render: ({ value }) => value ?? "Non renseigné",
  },
  {
    header: "Date de vérification de la traçabilité",
    name: "dateverificationtracabilite",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
    render: ({ value }) => value ?? "Non renseigné",
  },
  {
    header: "Cotisation payée",
    name: "cotisationpayee",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
        { id: null, label: "Non renseigné" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      if (value === false) {
        cellProps.style.background = colors.danger.background;
        cellProps.style.color = colors.danger.color;
        return "NON";
      }
      cellProps.style.background = colors.warning.background;
      cellProps.style.color = colors.warning.color;
      return "Non renseigné";
    },
  },
  {
    header: "Pénalité payée",
    name: "penalitepayee",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
        { id: null, label: "Non renseigné" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = colors.success.background;
        cellProps.style.color = colors.success.color;
        return "OUI";
      }
      if (value === false) {
        cellProps.style.background = colors.danger.background;
        cellProps.style.color = colors.danger.color;
        return "NON";
      }
      cellProps.style.background = colors.warning.background;
      cellProps.style.color = colors.warning.color;
      return "Non renseigné";
    },
  },
];

export const gridStyle = { minHeight: 600, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "siret", type: "string", operator: "contains", value: "" },
  { name: "nom", type: "string", operator: "contains", value: "" },
  { name: "prenom", type: "string", operator: "contains", value: "" },
  { name: "codeos", type: "string", operator: "contains", value: "" },
  { name: "codedc", type: "string", operator: "contains", value: "" },
  { name: "adresse", type: "string", operator: "contains", value: "" },
  { name: "codepostal", type: "float", operator: "contains", value: "" },
  { name: "nomcommune", type: "string", operator: "contains", value: "" },
  {
    name: "datereceptiontracabilite",
    type: "date",
    operator: "after",
    value: "",
  },
  {
    name: "dateverificationtracabilite",
    type: "date",
    operator: "after",
    value: "",
  },
  { name: "tracabiliterecue", type: "select", value: [], operator: "inlist" },
  { name: "cotisationpayee", type: "select", value: [], operator: "inlist" },
  { name: "penalitepayee", type: "select", value: [], operator: "inlist" },
];
