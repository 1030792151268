import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import SelectFilter from "@inovua/reactdatagrid-community/SelectFilter";
import { dateFilterEditorProps } from "../../../config/utils";
import colors from "../../../config/colors";

export const columns = [
  {
    header: "Exploitation",
    name: "raisonsociale",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Numéro de contrat",
    name: "numerocontrat",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Organisme stockeur",
    name: "nomorganismestockeur",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
  {
    header: "Montant de la cotisation HT",
    name: "prixcotisation",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ value }) => {
      return value + " € HT";
    },
  },
  {
    header: "Montant de la cotisation TTC",
    name: "prixcotisationttc",
    type: "number",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    render: ({ data }) => {
      data.prixcotisationttc = (data.prixcotisation * 1.2).toFixed(2);
      return (data.prixcotisation * 1.2).toFixed(2) + " € TTC";
    },
  },
  {
    header: "Date d'envoi de la facture",
    name: "dateenvoifacturecotisation",
    cellStyle: { textAlign: "center" },
    minWidth: 200,
    defaultFlex: 1,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    dateFormat: "YYYY-MM-DD",
    //enableColumnFilterContextMenu: false,
    filterEditor: DateFilter,
    filterEditorProps: dateFilterEditorProps,
    render: ({ value }) => value ?? "Non renseigné",
  },
  {
    header: "Abandon de contrat",
    name: "abandon",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: true, label: "OUI" },
        { id: false, label: "NON" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = "#f9d7da"; // DANGER
        cellProps.style.color = "#842029"; // DANGER
        return "OUI";
      }
      cellProps.style.background = "#d1e7dd"; // SUCCESS
      cellProps.style.color = "#0f5132"; // SUCCESS
      return "NON";
    },
  },
  {
    header: "Pénalité payée",
    name: "penalitepayee",
    cellStyle: { textAlign: "center" },
    minWidth: 100,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
    filterEditor: SelectFilter,
    filterEditorProps: {
      multiple: true,
      wrapMultiple: false,
      dataSource: [
        { id: null, label: "Pas de pénalité" },
        { id: true, label: "Payée" },
        { id: false, label: "Non payée" },
      ].map((c) => {
        return { id: c.id, label: c.label };
      }),
    },
    render: ({ cellProps, value }) => {
      cellProps.style.border = "1px solid";
      if (value === true) {
        cellProps.style.background = "#d1e7dd"; // SUCCESS
        cellProps.style.color = "#0f5132"; // SUCCESS
        return "Payée";
      }
      if (value === false) {
        cellProps.style.background = "#f9d7da"; // DANGER
        cellProps.style.color = "#842029"; // DANGER
        return "Non payée";
      }
      cellProps.style.background = colors.danger.background;
      cellProps.style.color = colors.danger.color; // SUCCESS
      return "Pas de pénalité";
    },
  },
  {
    header: "Commentaire",
    name: "commentaire",
    cellStyle: { textAlign: "center" },
    minWidth: 130,
    defaultFlex: 2,
    suppressSizeToFit: true,
    sortable: true,
    filter: true,
    resizable: true,
  },
];

export const gridStyle = { minHeight: 500, color: "#ffffff" };

export const defaultFilterValue = [
  { name: "raisonsociale", type: "string", operator: "contains", value: "" },
  { name: "numerocontrat", type: "string", operator: "contains", value: "" },
  { name: "commentaire", type: "string", operator: "contains", value: "" },
  {
    name: "nomorganismestockeur",
    type: "string",
    operator: "contains",
    value: "",
  },
  { name: "prixcotisation", type: "float", operator: "contains", value: "" },
  { name: "prixcotisationttc", type: "float", operator: "contains", value: "" },
  {
    name: "dateenvoifacturecotisation",
    type: "date",
    operator: "after",
    value: "",
  },
  { name: "abandon", type: "select", value: [], operator: "inlist" },
  { name: "penalitepayee", type: "select", value: [], operator: "inlist" },
];
