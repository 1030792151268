const droits = {
  modalite: {
    oui: 10,
    liste: 15,
    lecture: 20,
    modificationRestreintePourTiers: 25,
    modification: 30,
    creation: 40,
  },

  typesdroits: {
    GESTION_EXPLOITATION: "GESTION_EXPLOITATION",
    GESTION_CONTRAT: "GESTION_CONTRAT",
    GESTION_CONVENTION: "GESTION_CONVENTION",
    GESTION_UTILISATEUR: "GESTION_UTILISATEUR",
    ACCES_EXPLOITATION: "ACCES_EXPLOITATION",
    GESTION_REF_PRODUIT: "GESTION_REF_PRODUIT",
    GESTION_REF_VARIETE: "GESTION_REF_VARIETE",
    ACCES_PLAN_PRODUCTION: "ACCES_PLAN_PRODUCTION",
    EXPORT_DATA: "EXPORT_DATA",
    GESTION_PRODUCTEUR: "GESTION_PRODUCTEUR",
    GESTION_FORMATION: "GESTION_FORMATION",
    GESTION_REF_MATERIEL: "GESTION_REF_MATERIEL",
    GESTION_PARCELLE: "GESTION_PARCELLE",
    GESTION_CARTOGRAPHIE: "GESTION_CARTOGRAPHIE",
    GESTION_EVALUATION: "GESTION_EVALUATION",
    GESTION_DOCUMENT: "GESTION_DOCUMENT",
    GESTION_REFERENTIEL: "GESTION_REFERENTIEL",
    GESTION_COMMANDE: "GESTION_COMMANDE",
    ACCES_DOCUMENTATION: "ACCES_DOCUMENTATION",
    ACCES_PRODUCTEUR: "ACCES_PRODUCTEUR",
    ACCES_FORMATION: "ACCES_FORMATION",
    GESTION_CERTIFICATION_IGP: "GESTION_CERTIFICATION_IGP",
    GESTION_CERTIFICATION_EXPLOITATION: "GESTION_CERTIFICATION_EXPLOITATION",
    ACCES_CERTIFICATION_IGP: "ACCES_CERTIFICATION_IGP",
    ACCES_CERTIFICATION_EXPLOITATION: "ACCES_CERTIFICATION_EXPLOITATION",
    GESTION_ZONE_PRODUCTION: "GESTION_ZONE_PRODUCTION",
    ACCES_ADMINISTRATION: "ACCES_ADMINISTRATION",
    GESTION_PARAMETRE: "GESTION_PARAMETRE",
    APPEL_INTENTIONS_SEMIS: "APPEL_INTENTIONS_SEMIS",
    CONFIRMATION_SURFACES: "CONFIRMATION_SURFACES",
    APPEL_COTISATIONS: "APPEL_COTISATIONS",
    EXPLOITATIONS_ACTIVES_PRODUCTION: "EXPLOITATIONS_ACTIVES_PRODUCTION",
    LISTE_ROUGE: "LISTE_ROUGE",
    CONTRATS_NON_RENTRES: "CONTRATS_NON_RENTRES",
    CONTRATS_INCOMPLETS: "CONTRATS_INCOMPLETS",
    COMPARATIF_CONTRAT_INTENTION: "COMPARATIF_CONTRAT_INTENTION",
    COMPARATIF_CONTRAT_CONVENTION: "COMPARATIF_CONTRAT_CONVENTION",
    INTENTION_SEMIS_NON_RENTREES: "INTENTION_SEMIS_NON_RENTREES",
    COTISATIONS_IMPAYEES: "COTISATIONS_IMPAYEES",
    PARCELLES_INVALIDES: "PARCELLES_INVALIDES",
    PARCELLES_INVALIDES_TRACABILITE: "PARCELLES_INVALIDES_TRACABILITE",
    EXPLOITATION_PAR_ZONE_PRODUCTION: "EXPLOITATION_PAR_ZONE_PRODUCTION",
    EXPLOITATION_SANS_PRODUCTEUR: "EXPLOITATION_SANS_PRODUCTEUR",
    SURFACES_VARIETE_OS: "SURFACES_VARIETE_OS",
    ORGANISMES_STOCKEURS: "ORGANISMES_STOCKEURS",
    LIVRAISONS_NON_SAISIES: "LIVRAISONS_NON_SAISIES",
    RENDEMENTS: "RENDEMENTS",
    ATTESTATION_LIVRAISON_SEMENCES: "ATTESTATION_LIVRAISON_SEMENCES",
    ENVOI_GUIDES_CARNET_SUIVI: "ENVOI_GUIDES_CARNET_SUIVI",
    AVERTISSEMENTS: "AVERTISSEMENTS",
    CONSIGNES_RECOLTE: "CONSIGNES_RECOLTE",
    HISTORIQUE_FORMATIONS: "HISTORIQUE_FORMATIONS",
    GESTION_INTENTIONS_SEMIS: "GESTION_INTENTIONS_SEMIS",
    ACCES_INTENTIONS_SEMIS: "ACCES_INTENTIONS_SEMIS",
    ACCES_COMMANDE: "ACCES_COMMANDE",
    GESTION_PLAN_PRODUCTION: "GESTION_PLAN_PRODUCTION",
    GESTION_BILAN_RECOLTE: "GESTION_BILAN_RECOLTE",
    ACCES_BILAN_RECOLTE: "ACCES_BILAN_RECOLTE",
    GESTION_COTISATION: "GESTION_COTISATION",
    ACCES_CONTACT: "ACCES_CONTACT",
    ACCES_CONTRAT: "ACCES_CONTRAT",
    ACCES_VALIDATION_CONTRAT: "ACCES_VALIDATION_CONTRAT",
    ACCES_VALIDATION_INTENTION_SEMIS: "ACCES_VALIDATION_INTENTION_SEMIS",
    ACCES_CONVENTION: "ACCES_CONVENTION",
    ACCES_ORGANISME_STOCKEUR: "ACCES_ORGANISME_STOCKEUR",
    ACCES_SILO: "ACCES_SILO",
    ACCES_COTISATION: "ACCES_COTISATION",
    ACCES_PARCELLAIRE_CARTO: "ACCES_PARCELLAIRE_CARTO",
    ACCES_APPARIEMENT_PARCELLES_CARTO: "ACCES_APPARIEMENT_PARCELLES_CARTO",
    CONFORMITE_JACHERES_MELLIFERES: "CONFORMITE_JACHERES_MELLIFERES",
    ACCES_REFERENTIEL: "ACCES_REFERENTIEL",
    SILOS_LIVRAISON: "SILOS_LIVRAISON",
    SILOS_DEFINITIFS: "SILOS_DEFINITIFS",
    CODES_OS: "CODES_OS",
    TRACABILITE_CONTRATS: "TRACABILITE_CONTRATS",
  },

  profils: {
    ADMINISTRATEUR: "ADMINISTRATEUR",
    RESPO_ADMIN: "RESPO_ADMIN",
    CONSEILLER: "CONSEILLER",
    RESPO_OS: "RESPO_OS",
    TECH_OS: "TECH_OS",
    OS_COLLECTE_FINALE: "OS_COLLECTE_FINALE",
    PRODUCTEUR: "PRODUCTEUR",
    TRESORIER: "TRESORIER",
    STAGIAIRE_CONSEILLER: "STAGIAIRE_CONSEILLER",
    STAGIAIRE_ADMINISTRATIF: "STAGIAIRE_ADMINISTRATIF",
  },
};
export default droits;
