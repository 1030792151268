import { useContext, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
} from "reactstrap";
import StoreContext from "../../context/StoreContext";
import Fichier from "../../components/TableDocuments/Fichier";
import { RenderIf, createNotification, downloadFile } from "../../config/utils";
import EditionController from "../../config/apiUtils/EditionController";
import SpinLoadingAnimation from "../../components/SpinLoadingAnimation";
import Documents from "../moncompte/Documents";

export default function MesDocuments(props) {
  const store = useContext(StoreContext);
  const [loading, setLoading] = useState(false);

  const downloadContrat = async (contrat) => {
    setLoading(true);
    createNotification("info", "", "Préparation du document en cours...");
    const res = await EditionController.getContrat(contrat.idcontrat);
    downloadFile(
      res,
      `contrat_${store.millesime.idmillesime}-${contrat.numerocontrat}.pdf`,
      "application/octet-stream",
    );
    createNotification("success", "", "Contrat généré avec succès.");
    setLoading(false);
  };

  const downloadConvention = async (convention) => {
    setLoading(true);
    createNotification("info", "", "Préparation du document en cours...");
    const res = await EditionController.getConvention(convention.idconvention);
    downloadFile(
      res,
      `convention_${convention.raisonsociale.replaceAll(" ", "-")}_${
        convention.millesime
      }.pdf`,
      "application/octet-stream",
    );
    createNotification("success", "", "Convention générée avec succès.");
    setLoading(false);
  };

  const downloadFicheIdentificationRecolte = async () => {
    setLoading(true);
    createNotification("info", "", "Préparation du document en cours...");
    const res = await EditionController.getFicheIdentificationRecolte(
      store.exploitation.informations.idexploitation,
      store.millesime.idmillesime,
    );
    downloadFile(
      res,
      `fiche_identification_recolte_${store.exploitation.informations.siret}-${store.millesime.idmillesime}.pdf`,
      "application/octet-stream",
    );
    createNotification("success", "", "Attestation générée avec succès.");
    setLoading(false);
  };

  const downloadAttestationLivraisonSemences = async () => {
    setLoading(true);
    createNotification("info", "", "Préparation du document en cours...");
    const res = await EditionController.getAttestationLivraisonSemences(
      store.exploitation.informations.idexploitation,
      store.millesime.idmillesime,
    );
    downloadFile(
      res,
      `attestation_livraison_semences_${store.exploitation.informations.siret}-${store.millesime.idmillesime}.pdf`,
      "application/octet-stream",
    );
    createNotification("success", "", "Attestation générée avec succès.");
    setLoading(false);
  };

  const downloadAttestationPrix = async () => {
    setLoading(true);
    createNotification("info", "", "Préparation du document en cours...");
    const res = await EditionController.getAttestationPrix(
      store.exploitation.informations.idexploitation,
      store.millesime.idmillesime,
    );
    downloadFile(
      res,
      `attestation_prix_${store.exploitation.informations.siret}-${store.millesime.idmillesime}.pdf`,
      "application/octet-stream",
    );
    createNotification("success", "", "Attestation générée avec succès.");
    setLoading(false);
  };

  const renderFichierContrat = () => {
    const contrats = store.contratsExploitation.filter((c) => !c.abandon);
    return contrats.map((contrat, key) => (
      <Fichier
        key={key}
        filename={`Contrat de production ${contrat.numerocontrat}`}
        libelletypedocument={"Contrat de production"}
        onClickDownload={async () => await downloadContrat(contrat)}
      />
    ));
  };

  const renderFichierConvention = () => {
    const conventions = store.conventions.filter(
      (c) =>
        c.idexploitation === store.exploitation.informations.idexploitation,
    );
    return conventions.map((convention, key) => (
      <Fichier
        key={key}
        filename={`Convention d'adhésion ${convention.millesime}`}
        libelletypedocument={"Convention d'adhésion annuelle"}
        onClickDownload={async () => await downloadConvention(convention)}
      />
    ));
  };

  return (
    <>
      <Card
        style={{
          marginTop: 0,
          height: 500,
          overflowY: "auto",
        }}
      >
        <CardHeader>
          <Row>
            <Col>
              <CardTitle tag={"h5"}>Mes documents</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody style={styles.cardEmpty}>
          <RenderIf isTrue={loading}>
            <SpinLoadingAnimation />
          </RenderIf>
          <RenderIf isTrue={!loading}>
            <Table style={{ color: "#FFFFFF" }}>
              <thead>
                <tr>
                  <th></th>
                  <th>Type de document</th>
                  <th>Nom du fichier</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {renderFichierContrat()}
                {renderFichierConvention()}
                <Fichier
                  filename={`Attestation de livraison de semences ${store.millesime.idmillesime}`}
                  libelletypedocument={"Attestation"}
                  onClickDownload={downloadAttestationLivraisonSemences}
                />
                <Fichier
                  filename={`Attestation de prix ${store.millesime.idmillesime}`}
                  libelletypedocument={"Attestation"}
                  onClickDownload={downloadAttestationPrix}
                />
                <Fichier
                  filename={`Fiche d'identification de récolte ${store.millesime.idmillesime}`}
                  libelletypedocument={"Récolte"}
                  onClickDownload={downloadFicheIdentificationRecolte}
                />
              </tbody>
            </Table>
          </RenderIf>
        </CardBody>
      </Card>
      <Row style={{ marginTop: 10 }}>
        <Col>
          <Documents
            documents={props.documents}
            loadDocuments={props.loadDocuments}
          />
        </Col>
      </Row>
    </>
  );
}

const styles = {
  cardEmpty: {
    //minHeight: 500,
    justifyContent: "center",
  },
  cardNotEmpty: {
    minHeight: 500,
  },
};
