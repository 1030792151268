import { useContext, useEffect, useState } from "react";
import { Card } from "reactstrap";
import MapWrapper from "../../../components/carto/MapWrapper";
import StoreContext from "../../../context/StoreContext";
import ExploitationController from "../../../config/apiUtils/ExploitationController";
import GeoJSON from "ol/format/GeoJSON.js";
import { getTileLayerXYZ } from "../../../config/carto/utils";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { useNavigate } from "react-router-dom";
import CommuneController from "../../../config/apiUtils/CommuneController";
import droits from "../../../config/CodesDroits";

export default function WidgetCarto() {
  const context = useContext(StoreContext);
  const navigate = useNavigate();
  const layer = getTileLayerXYZ(context.carto.urlCarto.pac.url);
  const [geoJsonCommune, setGeoJsonCommune] = useState(null);
  const [layerParcellesExploitation] = useState(
    new VectorLayer({
      source: new VectorSource({
        features: [],
      }),
      style: {
        "stroke-width": 0.75,
        "stroke-color": "white",
        "fill-color": "rgba(0, 96, 191, 0.7)",
      },
    }),
  );

  const loadData = async () => {
    if (
      context.utilisateur.hasDroits(
        droits.typesdroits.GESTION_EXPLOITATION,
        droits.modalite.lecture,
      )
    ) {
      const resParcellaire =
        await ExploitationController.getParcellesByIdexploitation(
          context.exploitation.informations.idexploitation,
          context.millesime.idmillesime,
        );
      const geomParcellaire = [];
      resParcellaire.forEach((parcelle) => {
        if (parcelle.geometrie != null) {
          geomParcellaire.push(parcelle.geometrie);
        }
      });
      const features = [];
      geomParcellaire.forEach((geom) => {
        features.push(new GeoJSON().readFeatures(geom)[0]);
      });
      layerParcellesExploitation.getSource().addFeatures(features);

      const tmpGeoJsonCommune = await CommuneController.getGeometrieCommune(
        context.exploitation.informations.idcommune,
      );
      setGeoJsonCommune(tmpGeoJsonCommune);
    }
  };

  const zoomCommune = () => {
    if (geoJsonCommune) {
      context.carto.zoomMap(new GeoJSON().readFeatures(geoJsonCommune), {
        duration: 0,
        padding: [10, 10, 10, 10],
      });
    }
  };

  const zoomFeatures = () => {
    context.carto.zoomMap(
      layerParcellesExploitation.getSource().getFeatures(),
      {
        duration: 0,
        padding: [10, 10, 10, 10],
      },
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (context.carto.mapRef.current != null) {
      if (layerParcellesExploitation.getSource().getFeatures().length > 0) {
        zoomFeatures();
      } else {
        zoomCommune();
      }
    }
  });

  return (
    <Card style={{ height: 300, marginTop: 10, position: "relative" }}>
      <MapWrapper
        showZoomControl
        showScaleline
        onMapClick={() => {}}
        layers={[layer, layerParcellesExploitation]}
      />
      <button
        className={"ol-button-redirect"}
        onClick={() => navigate("/app/carto/parcellaire-exploitation")}
      >
        Ma carto
      </button>
    </Card>
  );
}
