import moment from "moment/moment";
import colors from "./colors";
import { ColDef } from "ag-grid-community";
import { ColGroupDef } from "ag-grid-community/dist/types/src/entities/colDef";

export const formatValueUnite = (
  value: string | number | null,
  unite: string,
  round: number = 2,
): string =>
  value !== null
    ? `${
        typeof value === "number" ? parseFloat(value.toFixed(round)) : value
      } ${unite}`
    : "";

export const valueFormatterUnite = (params: any, unite: string): string =>
  formatValueUnite(params.value, unite);

export const valueFormatterSurface = (params: any) =>
  valueFormatterUnite(params, "ha");

export const valueFormatterTonnage = (params: any) =>
  valueFormatterUnite(params, "T");

export const valueFormatterDate = (params: any) =>
  params.value ? moment(params.value).format("DD/MM/YYYY") : "";

export const cellStyleSwitch = (
  value: string | number | boolean | null | undefined,
  options: any,
) => {
  return options.find((o: any) => o.value === value).cellStyle;
};

export const cellStyleSwitchDefaultBoolean = (
  value: string | number | boolean | null | undefined,
  trueStyle = cellStyleSuccess,
  falseStyle = cellStyleDanger,
  nullStyle = cellStyleWarning,
) => {
  return cellStyleSwitch(value, [
    {
      value: true,
      cellStyle: trueStyle,
    },
    {
      value: false,
      cellStyle: falseStyle,
    },
    {
      value: null,
      cellStyle: nullStyle,
    },
    {
      value: undefined,
      cellStyle: nullStyle,
    },
  ]);
};

export const cellRendererSwitch = (
  value: string | number | boolean | null | undefined,
  options: any,
) => {
  return options.find((o: any) => o.value === value).renderer;
};

export const getColumnsByField = (
  columns: ColDef[],
  field: string | undefined,
) => {
  if (field === undefined) {
    return null;
  }

  const searchRecursively = (cols: any) => {
    for (const c of cols) {
      if (c.field === field) {
        return c;
      }
      const result: any = c.children && searchRecursively(c.children);
      if (result) {
        return result;
      }
    }
    return null;
  };

  return searchRecursively(columns);
};

export const getColumnsMapForCsv = (columns: (ColDef | ColGroupDef)[]): any => {
  const result = {};

  const extractColumns = (cols: (ColDef | ColGroupDef)[]) => {
    cols.forEach((col: ColDef | ColGroupDef) => {
      // @ts-expect-error typage à faire
      result[col.field] = col.headerName;

      // ici on vérifie si la colonne a des enfants
      if (
        (col as ColGroupDef).children !== undefined &&
        Array.isArray((col as ColGroupDef)?.children)
      ) {
        // si oui, on itère à travers en utilisant la recursion
        extractColumns((col as ColGroupDef)?.children);
      }
    });
  };

  extractColumns(columns);
  return result;
};

export const cellStyleSuccess = {
  border: "1px solid",
  textAlign: "center",
  color: colors.success.color,
  backgroundColor: colors.success.background,
};

export const cellStyleWarning = {
  border: "1px solid",
  textAlign: "center",
  color: colors.warning.color,
  backgroundColor: colors.warning.background,
};

export const cellStyleDanger = {
  border: "1px solid",
  textAlign: "center",
  color: colors.danger.color,
  backgroundColor: colors.danger.background,
};

export const cellStyleDisabled = {
  border: "1px solid",
  textAlign: "center",
  color: colors.disabled.color,
  backgroundColor: colors.disabled.background,
};

export const cellStyleDefault = {
  textAlign: "center",
};

export const cellRendererDefaultBoolean = [
  {
    value: true,
    renderer: "OUI",
  },
  {
    value: false,
    renderer: "NON",
  },
  {
    value: null,
    renderer: "Non renseigné",
  },
  {
    value: undefined,
    renderer: "Non renseigné",
  },
];

export const cellEditorParamsValuesDefaultBoolean = [
  {
    value: true,
    label: "OUI",
  },
  {
    value: false,
    label: "NON",
  },
  {
    value: null,
    label: "Non renseigné",
  },
];
